"use client"

import { usePathname } from "next/navigation"
import { FC } from "react"
import { Link } from "src/components/Link"
import { config } from "src/config"
import { breakpointPx, color, spacing } from "src/theme/variables"

export const Footer: FC = () => {
  const pathname = usePathname()

  return (
    <>
      <footer>
        <div className="FooterBody">
          <ul>
            <li>{config.email}</li>
            <li>{config.phone}</li>
          </ul>

          <nav>
            <ul>
              {pathname !== "/" && (
                <li>
                  <Link href={config.pages.index}>Zurück</Link>
                </li>
              )}
              <li>
                <Link href={config.pages.impressum}>Impressum</Link>
              </li>
              <li>
                <Link href={config.pages.datenschutz}>Datenschutz</Link>
              </li>
            </ul>
          </nav>
        </div>
      </footer>

      <style jsx>{`
        footer,
        nav,
        ul {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }

        footer {
          background: ${color.background};
        }

        .FooterBody {
          width: 100%;
          display: grid;
          gap: 3rem ${spacing.pageX};
          padding: 3rem ${spacing.pageX};
          width: ${breakpointPx.desktop};
          max-width: 100%;
          margin: 0 auto;
        }

        nav {
          white-space: nowrap;
        }

        ul {
          list-style: none;
          margin: 0;
          display: grid;
          gap: 1rem 2rem;
          padding: 0;
        }
      `}</style>
    </>
  )
}

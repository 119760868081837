"use client"

import { FC } from "react"
import { color } from "src/theme/variables"
import { A, AProps } from "./A"

export type LinkProps = AProps

export const Link: FC<LinkProps> = (props) => (
  <>
    <A {...props} />

    <style jsx>{`
      :global(a),
      :global(a:hover) {
        position: relative;
        color: inherit;
        cursor: pointer;
        text-underline-offset: 0.25rem;
        text-decoration-color: ${color.brand};
      }

      :global(a:hover) {
        text-decoration-color: inherit;
      }
    `}</style>
  </>
)

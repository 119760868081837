"use client"

import { FC, ReactNode } from "react"
import { breakpointPx, spacing } from "src/theme/variables"

export type MainProps = {
  children: ReactNode
}

export const Main: FC<MainProps> = ({ children }) => (
  <>
    <main>{children}</main>

    <style jsx>{`
      main {
        width: ${breakpointPx.desktop};
        max-width: 100%;
        margin: 0 auto;
        padding: ${spacing.pageY} ${spacing.pageX};
      }
    `}</style>
  </>
)

import NextjsLink, { LinkProps as NextjsLinkProps } from "next/link"
import { AnchorHTMLAttributes, CSSProperties, forwardRef } from "react"

export type AProps = AnchorHTMLAttributes<HTMLAnchorElement> &
  Omit<NextjsLinkProps, "href"> & { disabled?: boolean }

const disabledStyles: CSSProperties = { pointerEvents: "none" }

const appRegExp = /^\w+:/

/**
 * This is a generic unstyled link component that
 * - adds `target="_blank"` on external links
 * - adds `rel="noopener noreferrer"` on external links
 * - provides a `disabled` prop to support disabling links
 * - forces a plain link if the `download` attribute is present
 * - ensures anchors lead to the expected page
 * - prepends the current non-default locale to `href` to support opening pages
 * in a new tab with the correct locale using middle-click or the context menu
 */
export const A = forwardRef<HTMLAnchorElement, AProps>(
  (
    {
      children,
      href,
      as,
      replace,
      scroll,
      shallow,
      passHref,
      prefetch,
      locale,
      disabled,
      ...htmlLinkProps
    },
    ref,
  ) => {
    href = href || ""

    const nextjsLinkProps = {
      href,
      as,
      replace,
      scroll,
      shallow,
      passHref,
      prefetch,
      locale,
    }

    const isAbsolute = href.startsWith("http")

    // Next.js ignores the download attribute and opens the file instead of
    // downloading it. To force a download we need a regular HTML link.
    const isDownload = !!htmlLinkProps.download

    // e.g. `mailto:`, `tel:`
    const isApp = appRegExp.test(href)

    // External links should be opened in a new tab and the target page should
    // not have access to the opener window.
    // https://web.dev/external-anchors-use-rel-noopener
    const target = htmlLinkProps.target || (isAbsolute ? "_blank" : undefined)
    const rel =
      htmlLinkProps.rel || (isAbsolute ? "noopener noreferrer" : undefined)

    // Support disabling links by disabling focus and click.
    const style = disabled ? disabledStyles : undefined
    const tabIndex = disabled ? -1 : htmlLinkProps.tabIndex

    if (isAbsolute || isDownload || isApp) {
      return (
        <a
          {...htmlLinkProps}
          ref={ref}
          href={href}
          style={style}
          tabIndex={tabIndex}
          target={target}
          rel={rel}
        >
          {children}
        </a>
      )
    }

    return (
      <NextjsLink
        {...nextjsLinkProps}
        {...htmlLinkProps}
        ref={ref}
        href={href}
        style={style}
        tabIndex={tabIndex}
      >
        {children}
      </NextjsLink>
    )
  },
)

A.displayName = "A"

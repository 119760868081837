"use client"

import { FC, ReactNode } from "react"
import { Footer } from "src/content/Footer"
import { Main } from "src/layouts/Main"

export type DefaultPageLayoutProps = {
  children: ReactNode
}

export const DefaultPageLayout: FC<DefaultPageLayoutProps> = ({ children }) => (
  <>
    <div className="Page">
      <Main>{children}</Main>

      <Footer />
    </div>

    <style jsx>{`
      .Page {
        width: 100%;
        min-height: 100vh;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: 1fr auto;
        align-items: start;
      }
    `}</style>
  </>
)

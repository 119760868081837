import { usePathname } from "next/navigation"
import { FC } from "react"
import { Link } from "./Link"

export const Back: FC = () => {
  const pathname = usePathname()

  if (pathname === "/") {
    return null
  }

  return <Link href="/">Zurück</Link>
}

export const config = {
  meta: {
    title: "Christengemeinde Waldsassen",
    description:
      "Glaube an den Herrn Jesus Christus, so wirst du gerettet werden.",
    keywords:
      "jesus, christ, christen, bibel, gemeinde, gemeinschaft, glaube, gläubig, waldsassen, gottesdienst, sonntag, evangelisch, freikirchlich, freikirche",
    canonicalBaseUrl: "https://www.christengemeinde-waldsassen.de",
    themeColor: "#FFFFFF",
    maskIconColor: "#90c12f",
  },
  pages: {
    index: "/",
    impressum: "/impressum",
    datenschutz: "/datenschutz",
  },
  phone: "09632 / 916309",
  email: "info@christengemeinde-waldsassen.de",
  street: "Mühlbachgasse 8",
  zipCode: "95652",
  city: "Waldsassen",
  schedule: <>Sonntag 10:00 Uhr</>,
  telemediengesetz: (
    <>
      Thomas Bernreuther <br />
      Mühlbachgasse 8 <br />
      95652 Waldsassen
    </>
  ),
  notification: {
    text: "",
    showUntil: new Date(0),
  },
} as const

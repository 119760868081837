"use client"

import { FC, ReactNode } from "react"
import { media, spacing } from "src/theme/variables"
import { Back } from "./Back"

export type PageHeadingProps = {
  children: ReactNode
}

export const PageHeading: FC<PageHeadingProps> = ({ children }) => (
  <>
    <div className="PageHeading">
      <h1>{children}</h1>
      <Back />
    </div>

    <style jsx>{`
      .PageHeading {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: start;
        gap: ${spacing.paragraphY};
        margin-bottom: ${spacing.sectionY};
      }

      h1 {
        margin: 0;
      }

      @media (${media.tablet}) {
        .PageHeading {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }

      h1 {
        margin-top: 0;
      }
    `}</style>
  </>
)

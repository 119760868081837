export const font = {
  serif: "var(--font-amiri)",
  sans: "var(--font-open-sans)",
  lineHeight: "1.5",
  lineHeightHeading: "1.1",
  weight: 400,
  size: "110%",
  headingSizeFactor: 0.8,
}

export const color = {
  brand: "#85b32b",
  text: "#333",
  googleMapsBackground: "#e5e3df",
  background: "#eee",
} as const

export const border = {
  width: "2px",
} as const

export const spacing = {
  pageY: "10vh",
  pageX: "5vmin",
  sectionY: "3rem",
  sectionX: "3rem",
  paragraphY: "1rem",
  paragraphX: "1rem",
} as const

export const breakpoint = {
  mobile: 0,
  tablet: 768,
  desktop: 960,
  desktopHD: 1280,
  desktopFHD: 1920,
  desktopQHD: 2560,
  desktopUHD: 3840,
} as const

export type Viewport = keyof typeof breakpoint

export const breakpointPx: {
  [key in Viewport]: string
} = {
  mobile: breakpoint.mobile + "px",
  tablet: breakpoint.tablet + "px",
  desktop: breakpoint.desktop + "px",
  desktopHD: breakpoint.desktopHD + "px",
  desktopFHD: breakpoint.desktopFHD + "px",
  desktopQHD: breakpoint.desktopQHD + "px",
  desktopUHD: breakpoint.desktopUHD + "px",
}

export const media: {
  [key in Viewport]: string
} = {
  mobile: `min-width: ${breakpointPx.mobile}`,
  tablet: `min-width: ${breakpointPx.tablet}`,
  desktop: `min-width: ${breakpointPx.desktop}`,
  desktopHD: `min-width: ${breakpointPx.desktopHD}`,
  desktopFHD: `min-width: ${breakpointPx.desktopFHD}`,
  desktopQHD: `min-width: ${breakpointPx.desktopQHD}`,
  desktopUHD: `min-width: ${breakpointPx.desktopUHD}`,
}
